import React from 'react'
import didacheTile from '../images/didache-tile.png'

const Didache = () => {
	return (
		<div className="didache-bg flex md:flex-row flex-col gap-x-12 px-[11%] md:py-28 justify-center xs:items-center md:items-start py-12 h-full">
            <div className="flex md:flex-row flex-col justify-center items-start md:gap-x-2 gap-y-2 md:mb-10">
                <div className="vertical-text font-poppins text-xs xs:text-xl md:text-[22px]  font-regular tracking-widest">
                    RESOURCES
                </div>
                <div className=" border md:h-44 w-24 md:w-0 xs:border-blackZ border-blackZ"/>
            </div>
            <div className="flex md:flex-row xs:ml-[10%] md:ml-0  flex-col items-start xl:gap-14 lg:gap-x-7 gap-x-4">
                <div className=" w-[300px] md:w-[400px] flex flex-col">
                    <p className=" font-montserrat lg:text-3xl md:text-2xl text-xl leading-snug mt-4">
                        Get access to an endless  repertoire of sound Bible teachings at
                    </p>
                    <h1 className="text-6xl xs:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-clashMedium my-2">
                        Didaché <br/> Library
                    </h1>
                    <div className="md:flex hidden my-4">
                        <a href='https://www.didachelibrary.net/' target="_blank" rel="noopener noreferrer"  className="bg-orange py-3 md:px-20 px-11 font-poppins rounded-md text-white font-bold lg:text-xl hover:bg-white hover:text-orange hover:border-orange border transition ease-in-out duration-500"> Explore </a>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-start md:my-1 my-8">
                    <img src={didacheTile} alt="" className=" md:w-[320px] xs:w-[200px] xl:w-[400px] w-[300px] xl:mx-10" />
                </div>
                <div className="flex md:hidden  items-center justify-center 2xs:justify-start sm:my-4 w-full">
                    <a href='https://www.didachelibrary.net/' target="_blank" rel="noopener noreferrer" className="bg-orange py-2 px-14 font-poppins rounded-md text-white font-bold text-md hover:bg-white hover:text-orange hover:border-orange border transition ease-in-out duration-500"> Explore </a>
                </div>
                
            </div>
        </div>
	)
}

export default Didache