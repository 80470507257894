import React from 'react'
import { Whatsapp } from 'iconsax-react'
import Cursor from '../components/ui/Cursor'
import { Link } from 'react-router-dom';

interface cardProp{
    img: any;
    location: string;
    contact: string;
}

const ChurchCardB = (props: cardProp) => {
    const {img, location, contact} = props;
	return (
		<div className="flex flex-col md:gap-y-8 gap-y-4 min-w-[220px] md:w-full cursor-none">
            <Link to='findus' className=" h-84 overflow-hidden lg:rounded-[2.5rem] rounded-[1.5rem] cursor-none">
                <Cursor>
                    <img src={img} alt=""  className="rounded-md h-84 w-80 transform ease-in-out transition duration-500 hover:scale-150 hover:opacity-70"/>
                </Cursor>
            </Link>
            <h1 className="md:text-3xl text-xl font-clashRegular text-center ">{location}</h1>
            <a href='www.thenewbrook' className="md:flex hidden justify-center items-center gap-2 ">
                <Whatsapp size="32" color="#FF8A65" variant='Bold'/> 
                <a href={contact} className=" text-xl font-medium text-orange" target="_blank" rel="noopener noreferrer" >Contact info</a>
            </a> 
        </div>
	)
}

export default ChurchCardB