import React from 'react'

const NewsLetter = () => {
	return (
		<div className="flex flex-col justify-center items-center h-[100dvh] font-montserrat gap-6 p-[8%] bg-warmWhite">
            <div className="flex flex-col  items-center justify-center gap-y-8 max-w-[800px] border-2 p-[8%] shadow-2xl rounded-xl">  
                <h1 className="font-semibold lg:text-4xl md:text-3xl text-2xl text-center"> Subscribe to our Newsletter </h1>
                <div className="flex flex-col gap-y-8 w-full">
                    <input type="text" placeholder='Name' className="lg:p-5 md:p-4 p-3 rounded-md border-gray-400 border-2 md:text-lg text-sm w-full"/>
                    <input type="text" placeholder='Email' className="lg:p-5 md:p-4 p-3 rounded-md border-gray-400 border-2 md:text-lg text-sm w-full"/>

                </div>
                <button className="bg-orange lg:py-4 py-3 lg:px-12 px-8 rounded-md text-white font-bold lg:text-xl md:text-lg text-md hover:bg-white hover:text-orange hover:border-orange border transition ease-in-out duration-500">Submit</button>
            </div>
        </div>
	)
}

export default NewsLetter