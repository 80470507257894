import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import apic1 from '../images/Group 117.png';
import apic2 from '../images/Group 118.png';
import apic3 from '../images/apic1.png';
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";

const Aboutus = () => {
	const tabs = ['Our Story', 'Our Vision', 'Our Mission'];
	
	// State to keep track of the currently selected index in the carousel
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	
	// State to keep track of the currently selected tab name
	const [selectedTab, setSelectedTab] = useState(tabs[0]);

	// Update the selected tab name whenever the index changes
	useEffect(() => {
		setSelectedTab(tabs[selectedTabIndex]);
	}, [selectedTabIndex]);

	// Function to handle selecting a specific tab
	const handleSelectedTab = (index: any) => {
		setSelectedTabIndex(index);
	};

	// Function to navigate to the next tab
	const handleNext = () => {
		setSelectedTabIndex((prevIndex) => (prevIndex + 1) % tabs.length);
	};

	// Function to navigate to the previous tab
	const handlePrev = () => {
		setSelectedTabIndex((prevIndex) => (prevIndex - 1 + tabs.length) % tabs.length);
	};

	return (
		<div>
			<Navbar />
			<div className="aboutusHero-bg flex flex-col justify-center items-center px-20 text-white leading-[200px]">
				<h1 className="lg:text-8xl md:text-7xl sm:text-6xl text-5xl text-center font-clashMedium mt-5">
					Reinforcing your Identity
				</h1>
			</div>
			{/* Mobile view tab bar */}
			<div className="flex md:hidden justify-center ">
				<div className="relative my-16 flex">
					{/* Navigation Button - Previous */}
					<RxCaretLeft 
						className="text-4xl text-orange border-2 border-orange rounded-full absolute right-48 top-1/2 transform -translate-y-1/2"
						onClick={handlePrev}
					/>

					{/* Carousel Container */}
					<div className="flex justify-center">
						{tabs.map((tab, index) => (
							<div
								key={tab}
								className={`font-montserrat px-4 font-bold xl:text-3xl text-2xl hover:cursor-pointer ${selectedTab === tab ? 'text-orange' : 'text-blackX'}`}
								style={{ display: selectedTab === tab ? 'block' : 'none' }}
								onClick={() => handleSelectedTab(index)}
							>
								{tab.toUpperCase()}
							</div>
						))}
					</div>

					{/* Navigation Button - Next */}
					<RxCaretRight 
						className="text-4xl text-orange border-2 border-orange rounded-full absolute left-48 top-1/2 transform -translate-y-1/2"
						onClick={handleNext}
					/>
				</div>
			</div>

			{/* Desktop Tab navbar */}
			<div className="hidden md:flex mt-32 justify-center items-center lg:gap-x-32 md:gap-x-16 gap-x-10">
				{tabs.map((tab, index) => (
					<div
						key={tab}
						className={`font-montserrat font-semibold tracking-wider xl:text-2xl text-xl hover:cursor-pointer link-hover-effect-1 ${selectedTabIndex === index ? "text-orange" : 'text-blackX'}`}
						onClick={() => handleSelectedTab(index)}
					>
						{tab.toUpperCase()}
					</div>
				))}
			</div>

			<div className="flex flex-col justify-center items-center lg:px-[12%] px-[8%] pb-[3%] ">
				{
					selectedTab === "Our Story" && 
					<div className="md:my-10">
						<div className="flex md:flex-row flex-col justify-center items-center xl:gap-x-20 lg:gap-x-10 gap-x-5 my-10">
							<div className="basis-3/5">
								<h1 className="md:text-7xl text-4xl tracking-wider font-clashRegular my-5">Our Story</h1>
								<p className="font-montserrat md:text-lg text-sm !leading-[35px] text-justify">
									It begins with a young man baptized with fire and passion in a peculiar way. As a veterinary student at the Federal University of Agriculture, Abeokuta, his voice rang through the campus hostels getting many saved and drawing hundreds to roadside meetings. <br /> <br />
									Fred Adekunle had always had a fiery heart for missions, but it wasn’t until 2018 that the LORD put the vision of a local Church in his heart. Nestled at Ilaro, Ogun State after his graduation, our work grew to over 15 locations over the years.
									<span className="font-montserrat md:text-lg text-sm ;flex md:hidden !leading-[35px] text-justify">
										<br /><br />
										After 6 years of Church ministry, the LORD appeared again, and commanded a new brook from which the nations will drink and be refreshed. It was a command to educate, establish, and reinforce the identity of the believer.
									</span>
								</p>
							</div>
							<div className="basis-2/5 my-12 flex items-center justify-center px-12 md:px-0">
								<img src={apic1} alt="" className="w-96" />
							</div>
						</div>
						<p className="font-montserrat md:text-lg hidden md:flex text-sm !leading-[35px] text-justify">
							After 6 years of Church ministry, the LORD appeared again, and commanded a new brook from which the nations will drink and be refreshed. It was a command to educate, establish, and reinforce the identity of the believer.
						</p>
					</div>
				}
				{
					selectedTab === "Our Vision" &&
					<div className="md:my-10">
						<div className="flex flex-col md:flex-row justify-center items-center gap-x-12 md:my-10">
							<div className="basis-2/5 my-12 flex items-center justify-center px-12 md:px-0 md:order-1 order-2">
								<img src={apic2} alt="" />
							</div>
							<div className="basis-3/5 order-1 md:order-2 mt-10">
								<h1 className="md:text-7xl text-4xl tracking-wider font-clashRegular my-5">Our Vision</h1>
								<p className="font-montserrat md:text-lg text-sm !leading-[35px] text-justify">
									At the NewBrook Church, our vision is to build a growing house for God’s new creation—a community that educates and enforces the identity of the man in Christ, and within which he walks in the realities of his redemption. <br /><br />
									In a world of several voices that try to conform every person, we hold that man can only truly find identity and meaning for his existence in Christ Jesus. We believe that the plan of God for all mankind is Church. We hold that the Church, and particularly, the local assembly, is man’s only safe place of sanity. Outside the Church, there’s only chaos and confusion.
								</p>
                                <p className="font-montserrat md:text-lg text-sm !leading-[35px] text-justify">
                                    <br/>
                                    Because of this, our goal is to include as many as possible in the Church of Jesus. Our goal is to help everyone to see themselves exactly as God sees them, and to live exactly as He designed for them to.
                                </p>
							</div>
						</div>
						<p className="font-montserrat md:text-lg text-sm !leading-[35px] text-justify md:flex hidden">
							Because of this, our goal is to include as many as possible in the Church of Jesus. Our goal is to help everyone to see themselves exactly as God sees them, and to live exactly as He designed for them to.
						</p>
					</div>
				}
				{
					selectedTab === "Our Mission" &&
					<div className="md:my-10">
						<div className="flex md:flex-row flex-col justify-center items-center gap-x-12 my-10">
							<div className="basis-3/5">
								<h1 className="md:text-7xl text-4xl tracking-wider font-clashRegular my-5">Our Mission</h1>
								<p className="font-montserrat md:text-lg text-sm !leading-[35px] text-justify">
									Our mission is to present the Gospel of Jesus, the truth of God and His eternal counsel in all its fullness, as shown in the Scriptures to everyone and anyone everywhere. <br/>
                                    We will do this by: <br/>
                                    <b>Radical evangelism</b> , preaching the Gospel to the unsaved everywhere. <br/><br/>

                                    <b>Discipleship</b> as commanded by Jesus Christ, with emphasis on exhaustive and thorough teaching of Scriptures until the Saints are established in truth <br/><br/>

                                    <b>Prayers Unending</b>, because we trust only in the power of the Spirit to transform men and to liberate minds
								</p>
                                <p className="font-montserrat md:text-lg text-sm !leading-[35px] text-justify md:hidden block">
                                    <br/>
                                    <b>Demonstrations of the gifts and operations of the Spirit</b> as proof of God’s present willingness to intervene in the affairs of man.
                                </p>
							</div>
							<div className="basis-2/5 my-12 flex items-center justify-center px-12 md:px-0">
								<img src={apic3} alt="" />
							</div>
						</div>
						<p className="font-montserrat md:text-lg text-sm !leading-[35px] text-justify md:block hidden">
							 <b>Demonstrations of the gifts and operations of the Spirit</b> as proof of God’s present willingness to intervene in the affairs of man.
						</p>
					</div>
				}
			</div>
			<Footer />
		</div>
	);
}

export default Aboutus;