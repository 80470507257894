import React, {useState, useEffect} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from '../components/Navbar'
import cPic1 from '../images/churchpic1.png'
import cPic2 from '../images/churchpic2.png'
import cPic3 from '../images/churchpic3.png'
import cPic4 from '../images/churchpic4.png'
import cPic5 from '../images/churchpic5.png'
import cPic6 from '../images/churchpic6.png'
import cPic7 from '../images/churchpic7.png'
import cPic8 from '../images/churchpic8.png'
import cPic9 from '../images/churchpic9.png'
import cPic10 from '../images/churchpic10.png'
import cPic11 from '../images/churchpic11.png'
import cPic12 from '../images/churchpic12.png'
import cPic13 from '../images/churchpic13.png'
import cPic14 from '../images/churchpic14.png'
import cPic15 from '../images/churchpic15.png'
import cPic16 from '../images/churchpic16.png'
import cPic17 from '../images/churchpic17.png'
import cPic18 from '../images/churchpic18.png'
import cPic19 from '../images/churchpic19.png'
import cPic20 from '../images/churchpic20.png'
import cPic21 from '../images/churchpic21.png'
import cPic22 from '../images/churchpic22.png' 
import cPic23 from '../images/churchpic23.png' 
import cPic24 from '../images/churchpic24.png' 
import Footer from '../components/Footer'
import ChurchCard from '../components/ChurchCard'
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";

function SampleNextArrow(props: any) { 
  const { className, currentSlide, slideCount, onClick, slidesToShow } = props;
  console.log(slideCount, currentSlide, slidesToShow);
  
  return (
    <div onClick={onClick} >
        <button onClick={onClick} className={className} >
            <RxCaretRight className={`text-6xl text-black absolute bottom-[160px] right-[1px] ${currentSlide >= slideCount - slidesToShow ? 'text-gray-200 cursor-not-allowed' : ''}`}
                onClick={onClick}
                
            />
        </button>
    </div>
  ); 
}

function SamplePrevArrow(props: any) {
  const { className, currentSlide, onClick } = props;
  return (
    <div onClick={onClick}>
        <button onClick={onClick} className={className} >
            <RxCaretLeft className={`text-6xl text-black absolute bottom-[160px] ${currentSlide === 0 ? 'text-gray-200 cursor-not-allowed' : ''}`}
                onClick={onClick}
            />
        </button>
    </div> 
  );
}

const Findus = () => {
    // const [selectedState, setSelectedState] = useState("lagos");
    // const handleSelectedState = (selectedState: string) => {
    //     setSelectedState(selectedState)
    // }
    const states = ['lagos', 'ogun', 'oyo', 'ekiti', 'abuja', 'osun'];

    // State to keep track of the currently selected index in the carousel
    const [selectedStateIndex, setSelectedStateIndex] = useState(0);
    
    // State to keep track of the currently selected state by name
    const [selectedState, setSelectedState] = useState(states[0]);

    // Update the selected state name whenever the index changes
    useEffect(() => {
        setSelectedState(states[selectedStateIndex]);
    }, [selectedStateIndex]);

    // Function to handle selecting a specific state
    const handleSelectedState = (index: any) => {
        setSelectedStateIndex(index);
    };

    // Function to navigate to the next state
    const handleNext = () => {
        setSelectedStateIndex((prevIndex) => (prevIndex + 1) % states.length);
    };

    // Function to navigate to the previous state
    const handlePrev = () => {
        setSelectedStateIndex((prevIndex) => (prevIndex - 1 + states.length) % states.length);
    };

    const settings = {
        dots: true,
        infinite: false,
        focusOnSelect: true,
        // slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 800,
        autoplaySpeed: 2000,
        cssEase: "linear",
        className: "center",
        nextArrow: <SampleNextArrow slidesToShow={3}/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          nextArrow: <SampleNextArrow slidesToShow={2}/>,
          infinite: false,
        //   dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          nextArrow: <SampleNextArrow slidesToShow={1}/>,
          infinite: false,
        //   slidesToScroll: 1,
        }
      }
    ]
    };

	return (
		<div>
            <Navbar/>
            <div className="findusHero-bg flex flex-col justify-center items-center px-8 text-white leading-[200px]" id='top'>
                <h3 className="lg:text-4xl md:text-3xl sm:text-xl text-lg font-montserrat font-light">Someone is calling your name!</h3>
                <h1 className="lg:text-9xl md:text-7xl text-6xl text-center font-clashMedium mt-5">Can you hear it?</h1>
                <p className="max-w-[700px] font-montserrat lg:text-xl md:text-lg text-base text-center leading-relaxed mt-12 font-light">
                    Listen closely. It is the voice of Jesus cal    ling you home and fixing you where you belong—with us.
                </p>
            </div>

            <div className="map-bg">
                <div className=" flex flex-col items-center justify-center px-36 pt-24">
                    
                    {/* Mobile view tab bar */}
                    <div className="relative my-16 flex md:hidden">
                        {/* Navigation Button - Previous */}
                        {/* <button className="absolute left-0 top-1/2 transform -translate-y-1/2" onClick={handlePrev}>
                            &lt;
                        </button> */}
                        <RxCaretLeft 
                            className="text-4xl text-orange border-2 border-orange rounded-full absolute right-44 top-1/2 transform -translate-y-1/2 "
                            onClick={handlePrev}
                        />

                        {/* Carousel Container */}
                        <div className="flex justify-center">
                            {states.map((state, index) => (
                                <div
                                    key={state}
                                    className={`font-montserrat font-bold xl:text-3xl text-2xl hover:cursor-pointer ${selectedState === state ? 'text-orange' : 'text-blackX'}`}
                                    style={{ display: selectedState === state ? 'block' : 'none' }}
                                    onClick={() => handleSelectedState(index)}
                                >
                                    {state.toUpperCase()}
                                </div>
                            ))}
                        </div>

                        {/* Navigation Button - Next */}
                        {/* <button className="absolute right-0 top-1/2 transform -translate-y-1/2" onClick={handleNext}>
                            &gt;
                        </button> */}
                        <RxCaretRight 
                            className="text-4xl text-orange border-2 border-orange rounded-full absolute left-44  top-1/2 transform -translate-y-1/2"
                            onClick={handlePrev}
                        />
                    </div>
                    {/* Desktop view tab bar */}
                    
                    <div className="hidden md:flex xl:gap-x-28 lg:gap-x-20 md:gap-x-10 my-16">
                        {states.map((state, index) => (
                        <div
                            key={state}
                            className={`font-montserrat font-bold xl:text-3xl text-2xl hover:cursor-pointer ${selectedStateIndex === index ? "text-orange" : 'text-blackX'}`}
                            onClick={() => handleSelectedState(index)}
                        >
                            {state.toUpperCase()}
                        </div>
                        ))}
                    </div>

                </div>
                
                {
                    selectedState === "lagos" &&
                    <div className="slider-container px-8 sm:px-18 md:px-24 ">
                        <Slider {...{...settings, slidesToShow: 3}}>
                            <ChurchCard 
                                    img={cPic19}
                                    location={'YABA'}
                                    address={'NSPRI House, opposite Car wash Bus stop, University Road, Akoka-Yaba'}
                                    walink={"https://wa.me/+2348166430332"}
                                    type={'Main Campus'}
                                />
                                <ChurchCard 
                                    img={cPic11}
                                    location={'IKORODU'}
                                    address={'Lagos State University, Science and Technology, Ikorodu'}
                                    walink={"https://wa.me/+2348168813422"}
                                    type={'Connect Creek 1'}
                                />
                                <ChurchCard 
                                    img={cPic20}
                                    location={'MAGBORO'}
                                    address={'Plot 4/6 Alamo Road, Magboro'}
                                    walink={"https://wa.me/+2348164931651"}
                                    type={'Connect Creek 2'}
                                />
                                <ChurchCard 
                                    img={cPic4}
                                    location={'OJO'}
                                    address={'Beside the Chapel of Light, Lagos State University Teaching Hospital, Ojo'}
                                    walink={"https://wa.me/+2348164931651"}
                                    type={'Connect Creek 3'}
                                />
                                <ChurchCard 
                                    img={cPic22}
                                    location={'MUSHIN'}
                                    address={'Unilag Staff School Annex, LUTH, Idi-Araba, Mushin'}
                                    walink={"https://wa.me/+2348166430332"}
                                    type={'Connect Creek 4'}
                                />
                                

                        </Slider>
                    </div>
                }
                {
                    selectedState === "ogun" &&
                    <div className="slider-container px-8 sm:px-18 md:px-24 ">
                        <Slider {...{...settings, slidesToShow: 3}}>
                            <ChurchCard 
                                img={cPic5}
                                location={'FUNAAB'}
                                address={'GTView Viewing Centre, Harmony Estate, Alabata, FUNAAB'}
                                walink={"https://wa.me/+2347032522952"}
                                type={'Main Campus'}
                            />
                            <ChurchCard 
                                img={cPic7}
                                location={'ILARO'}
                                address={'WHITEFIELD MISSIONS CENTRE, OPPOSITE SCHOOL 2 JUNCTION, ORITA, ILARO'}
                                walink={"https://wa.me/+2348140356732"}
                                type={'Main Campus'}
                            />
                            <ChurchCard 
                                img={cPic17}
                                location={'AGO-IWOYE'}
                                address={'RCCG Hall, Opposite Miami Lodge, Mariam, Ago-Iwoye'}
                                walink={"https://wa.me/+2347064018417"}
                                type={'Main Campus'}
                            />
                            <ChurchCard 
                                img={cPic18}
                                location={'OSIELE'}
                                address={'Room 3, LASU building, Federal College of Education, Osiele'}
                                walink={"https://wa.me/+2349076896541"}
                                type={'Connect Creek 1'}
                            />
                            <ChurchCard 
                                img={cPic15}
                                location={'SHAGAMU'}
                                address={'No 1 Ilaye, Blue building beside NNPC filling station, Isale Oko, Shagamu'}
                                walink={"https://wa.me/+2349026439884"}
                                type={'Main Campus'}
                            />
                            <ChurchCard
                                img={cPic23}
                                location={'IJEBU-ODE'}
                                address={' ETF opposite cohum building, Tai Solarin University of Education, Ijebu-Ode'}
                                walink={"https://wa.me/+2348106134014"}
                                type={'Connect Creek 2'}
                            />
                            <ChurchCard 
                                img={cPic16}
                                location={'ILESE'}
                                address={'Ogun State Poly-technique of Health and Allied Institution of Sciences, Ilese'}
                                walink={"https://wa.me/+2348076386578"}
                                type={'Connect Creek 3'}
                            />
                            
                            
                        </Slider>
                    </div>
                }
                {
                    selectedState === "oyo" &&
                    <div className="slider-container px-8 sm:px-18 md:px-24 ">
                        <Slider {...{...settings, slidesToShow: 3}}>
                            <ChurchCard 
                                img={cPic12}
                                location={'AGBOWO'}
                                address={'Abadina Primary School 3, Abadina, University of Ibadan, Ibadan'}
                                walink={"https://wa.me/+2348107369310"}
                                type={'Main Campus'}
                            />

                            <ChurchCard 
                                img={cPic21}
                                location={'POLYIBADAN'}
                                address={'Polytechnic of Ibadan, Iyana Poly Road, Sango, Ibadan'}
                                walink={"https://wa.me/+2349058048243"}
                                type={'Connect Creek 1'}
                            />
                            <ChurchCard 
                                img={cPic6}
                                location={'OGBOMOSHO'}
                                address={'Computer science shed, lautech, Ogbomoso, Ibadan'}
                                walink={"https://wa.me/+2349035499039"}
                                type={'Connect Creek 2'}
                            />

                        </Slider>
                        
                    </div>
                }
                {
                    selectedState === "ekiti" &&
                    <div className="slider-container px-8 sm:px-18 md:px-24 ">
                        
                            <ChurchCard 
                                img={cPic24}
                                location={'OYE-EKITI'}
                                address={'Mechatronics Classroom 3, Engineering Faculty, Federal University Oye-Ekiti, Ikole Campus'}
                                walink={"https://wa.me/+2348134334197"}
                                type={'Connect Creek'}
                            />
                        
                    </div>
                }
                {
                    selectedState === "abuja" &&
                    <div className="slider-container px-8 sm:px-18 md:px-24 ">
                        
                            <ChurchCard 
                                img={cPic10}
                                location={'UNIABUJA'}
                                address={'Tetfund Block B, University of Abuja'}
                                walink={"https://wa.me/+2348145457688"}
                                type={'Connect Creek'}
                            />
                        
                    </div>
                }
                {
                    selectedState === "osun" &&
                    <div className="slider-container px-8 sm:px-18 md:px-24 ">
                        
                        <Slider {...{...settings, slidesToShow: 3}}>

                            <ChurchCard 
                                img={cPic13}
                                location={'OAU'}
                                address={'Social Sciences Faculty Basement, Obafemi Awolowo University'}
                                walink={"https://wa.me/+2348108681685"}
                                type={'Main Campus'}
                            />
                            <ChurchCard 
                                img={cPic1}
                                location={'ILESA'}
                                address={"Adejumo Hall, in front of Man O' War Meeting Point, Opposite Eco Arena, University of Ilesa, Ilesa"}
                                walink={"https://wa.me/+2348164915617"}
                                type={'Connect Creek 1'}
                            />
                            <ChurchCard 
                                img={cPic14}
                                location={'EDE'}
                                address={"Redeemer's university auditorium, Redeemer university, Ede."}
                                walink={"https://wa.me/+2349035499039"}
                                type={'Connect Creek 2'}
                            />
                        </Slider>
                    
                    
                    </div>
                }
            </div>
            <Footer />
        </div>
	)
}

export default Findus