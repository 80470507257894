import React from 'react'
import {Whatsapp } from 'iconsax-react'
import { FaLocationDot } from "react-icons/fa6";

interface cardProp {
    img: any;
    location: string;
    address: string;
    walink: string;
    type: string;
}

const ChurchCard = (props: cardProp) => {
    const {img, location, address, walink, type} = props;
	return (
		<div className="flex flex-col gap-y-8 items-center mb-24 ">
            <div className="overflow-hidden rounded-[2.5rem]">
                <img src={img} alt=""  className="rounded-md w-[250px] transform ease-in-out transition duration-500 hover:scale-150 hover:opacity-70"/>
            </div>
            <div className="flex flex-col items-center justify-center gap-y-4">
                <div className="flex justify-center items-center gap-x-3">
                    <FaLocationDot className="md:text-2xl text-lg" />
                    <h1 className="md:text-2xl text-xl font-clashRegular text-center ">{location}</h1>
                </div>

                <p className="w-[300px] text-center md:text-base text-sm font-montserrat">
                    {address} <br/>
                    <b>{type}</b>
                </p>
                
                <a href={walink} className="flex justify-center w-fit md:px-6 px-4 border-2 rounded-lg border-orange py-2 items-center gap-2 ">
                    <Whatsapp size="24" color="#FF8A65" variant='Bold'/> 
                    <span className=" md:text-xl text-base font-medium text-orange">Contact us</span>
                </a>

            </div>
        </div>
	)
}

export default ChurchCard