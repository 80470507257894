import React from "react"
import Landing from '../pages/Landing'
import Findus from "../pages/Findus"
import Aboutus from "../pages/Aboutus"

const routes = [
    {
        path: '/',
        element: <Landing />
    },
    {
        path: '/findus',
        element: <Findus />
    },
    {
        path: '/aboutus',
        element: <Aboutus /> 
    },
]

export default routes