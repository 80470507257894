import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/logo.svg';
import { PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi';
import { MdOutlineCancel } from "react-icons/md";
import { HashLink } from "react-router-hash-link";

const Navbar: React.FC = () => {
	const location = useLocation();
	const [dropdown, setDropdown] = useState<boolean>(false);
	const [currTab, setCurrTab] = useState<string>('');

	useEffect(() => {
		const pathToTabMap: { [key: string]: string } = {
			'/aboutus': 'about',
			'/findus': 'findus',
			'/#conferences': 'conf',
			'/#didache': 'resources',
			'/': 'home',
		};

		setCurrTab(pathToTabMap[location.pathname] || '');
	}, [location]);

	const toggleDropdown = () => {
		setDropdown((prev) => !prev);
	};

	return (
		<>
			<div className="bg-blackN absolute w-full py-4 flex justify-center items-center text-white font-montserrat">
				<ul className="md:flex space-x-4 lg:gap-x-12 text-sm items-center hidden">
					<li className="hidden sm:flex"> 
						X
					</li>
					<li>
						<Link
							to="/aboutus"
							className={`text-white hover:text-gray-300 mx-5 font-semibold ${
								currTab === 'about' ? 'text-yellow-500font-bold underline underline-offset-8 ' : ''
							}`}
						>
							ABOUT
						</Link>
					</li>
					<li>
						<Link
							to="/findus"
							className={`text-white hover:text-gray-300 mx-5 font-semibold ${
								currTab === 'findus' ? 'font-bold underline underline-offset-8 ' : ''
							}`}
						>
							FIND US
						</Link>
					</li>
					<li className="lg:pl-24 pl-20 lg:pr-8 pr-6">
						<Link to="/">
							<img src={logo} alt="Logo" className="w-18" />
						</Link>
					</li>
					<li className="pl-6">
                        <HashLink smooth to="/#conferences" className={`text-white hover:text-gray-300 mx-5 font-semibold ${
								currTab === 'conf' ? 'font-bold underline underline-offset-8 ' : ''
							}`}>
                            CONFERENCES
                        </HashLink>
						
					</li>
					<li>
						<HashLink smooth
							to="/#didache"
							className={`text-white hover:text-gray-300 mx-5 font-semibold ${
								currTab === 'resources' ? 'font-bold underline underline-offset-8 ' : ''
							}`}
						>
							RESOURCES
						</HashLink>
					</li>
				</ul>

				{/* Mobile Navbar */}
				<div className="flex justify-between items-center md:hidden w-full px-[5%]">
					<Link to="/">
						<img src={logo} alt="Logo" className="w-14" />
					</Link>
					<div className="border-2 flex border-white rounded-full p-1" onClick={toggleDropdown}>
						{dropdown ? (
							<PiCaretUpBold className="text-md text-white" />
						) : (
							<PiCaretDownBold className="text-md text-white" />
						)}
					</div>
				</div>
			</div>

			{/* Dropdown Menu */}
			<div
				className={`flex-col w-full h-[70%] justify-center items-center gap-y-14 bg-blackA z-50 absolute ${
					dropdown ? 'flex' : 'hidden'
				}`}
			>
				
				<span className="text-white font-montserrat" onClick={toggleDropdown}>
					<MdOutlineCancel className="text-2xl text-white" />
					
				</span>
				<span>
					<Link
						to="/aboutus"
						className={`text-white hover:text-gray-300 mx-5 font-semibold ${
							currTab === 'about' ? 'font-bold underline underline-offset-8 ' : ''
						}`}
					>
						ABOUT
					</Link>
				</span>
				<span>
					<Link
						to="/findus"
						className={`text-white hover:text-gray-300 mx-5 font-semibold ${
							currTab === 'findus' ? 'font-bold underline underline-offset-8 ' : ''
						}`}
					>
						FIND US
					</Link>
				</span>
				<span>
					<Link
						to="/#conferences"
						className={`text-white hover:text-gray-300 mx-5 font-semibold ${
							currTab === 'conf' ? 'font-bold underline underline-offset-8 ' : ''
						}`}
					>
						CONFERENCES
					</Link>
				</span>
				<span>
					<HashLink smooth
							to="/#didache"
							className={`text-white hover:text-gray-300 mx-5 font-semibold ${
								currTab === 'resources' ? 'font-bold underline underline-offset-8 ' : ''
							}`}
						>
							RESOURCES
						</HashLink>
				</span>
			</div>
		</>
	);
};

export default Navbar;
