import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion";
import Navbar from '../components/Navbar'
import newbrook from '../images/the new brook (7).png'
import Footer from '../components/Footer'
import NewsLetter from '../components/NewsLetter'
import hcLogo from '../images/hc-logo.svg'
import metaLogo from '../images/meta-logo.png'
import Didache from '../components/Didache'
import wwa from '../images/wwa.png'
import wwaMob from '../images/wwa-mob.png'
import cPic19 from '../images/churchpic19.png'
import cPic14 from '../images/churchpic14.png'
import cPic5 from '../images/churchpic5.png'
import mlr from '../images/MLR 24 1.svg'
import flutterwave from '../images/flutterwave.png'
import cashapp from '../images/cashapp.png'
import { PiCaretDownBold } from "react-icons/pi";
import ChurchCardB from '../components/ChurchCardB'

const Landing = () => {
    
    return (
        <div>
            <div className="">

                <Navbar />

                <div className="hero-bg h-[100vh] flex flex-col justify-center items-center text-white">
                    <div className="mb-8">
                        <img src={newbrook} alt="" className="h-80 xs:h-80 md:h-96" />
                    </div>
                    <div className="flex flex-col justify-center text-center items-center md:w-[600px]   xs:my-5 absolute xs:bottom-40 2xs:bottom-36 bottom-28">
                        <p className="font-montserrat xs:leading-loose sm:text-xl md:text-lg xs:text-lg text-sm px-6">
                            We have drunk of the fountain of life that Jesus is—He is to us our <span className="font-semibold italic">identity</span> and purpose. We are restoring life and hope to every people, filling hearts with joy resounding
                        </p>

                        <a href='#wwa' className="border-2 xs:hidden flex border-orange rounded-full xs:mt-0 mt-4  p-4">
                            <PiCaretDownBold className="2xs:text-3xl text-2xl text-orange"/>
                        </a>
                    </div>

                   
                </div>

            </div>

            <div className=" flex flex-col justify-center px-[10%] xs:px-[11%] lg:py-28 py-14" id="wwa" >
                <div className="flex md:flex-row flex-col justify-center lg:gap-x-24 md:;gap-x-12 gap-x-8  w-full">
                    <div className="md:my-5 my-2">
                        <div className='flex md:flex-row flex-col justify-center md:items-start gap-y-2 gap-x-2 md:mb-10'>
                            <div className="vertical-text font-poppins text-xs md:text-2xl lg:text-2xl font-regular tracking-widest">
                                WHO WE ARE
                            </div>
                            <div className=" border md:border-r lg:h-64 md:h-56 w-24 md:w-0 border-blackZ"/>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center max-w-[780px] ">
                        <h3 className="font-montserrat md:text-4xl text-3xl mb-3 md:my-4">Discover Us</h3>
                        <h1 className="lg:text-6xl md:text-5xl xs:text-4xl text-3xl font-semibold font-clashRegular max-w-[750px] ">Our Heritage, Mission and
                            Vision </h1>
                        <img src={wwa} alt="" className="md:flex hidden my-6 w-[700px]" />
                        <img src={wwaMob} alt="" className="md:hidden flex my-6"/>
                        <p className="font-montserrat md:text-lg sm:text-base text-sm mb-5 md:my-7 leading-normal max-w-[750px]">
                            At the <b>NewBrook Church</b> , our vision is to build a growing house for God’s new creation—a community that educates and enforces the identity of the man in Christ....
                        </p>

                    </div>
                </div>
                <div className=" flex justify-center w-full">
                    <Link to='/aboutus' className="bg-orange py-3 md:px-20 px-11 font-poppins rounded-md text-white font-bold text-lg hover:bg-white hover:text-orange hover:border-orange border transition ease-in-out duration-500">Read More</Link>
                </div>
            </div>
            
            <div id='didache'>
                <Didache />
            </div>

            <div className='flex flex-col justify-center items-center md:px-[11%] px-[6%] md:pt-48 py-[15%] '>  
                <div className="md:flex  gap-5 md:my-6 my-4">
                    <div className="flex md:flex-row flex-col justify-center md:items-start lg:gap-x-4 md:gap-x-2 gap-y-2 md:mb-10 mb-4">
                        <div className="vertical-text font-poppins text-md xs:text-xl md:text-2xl font-regular tracking-widest">
                            FIND US
                        </div>
                        <div className=" border md:border-r-2 md:h-36 w-20 md:w-0 border-blackZ"/>
                    </div>
                    <div className="flex flex-col xl:mx-20 tracking-tighter items-start">
                        <h3 className=" lg:text-5xl text-2xl w-56 font-montserrat font-light md:w-auto xs:w-64">Someone is calling your name!</h3>
                        <h1 className="xl:text-8xl lg:text-7xl text-6xl font-clashMedium md:tracking-wider md:!leading-[90px]">Can you hear it?</h1>
                        <p className="max-w-[650px] font-montserrat md:text-2xl text-xl my-6 md:leading-8 flex ">
                            Listen closely. It is the voice of Jesus calling you home and fixing you where you belong—with us.
                        </p>
                    </div>
                </div>
                <div className="border hidden xs:flex border-gray-300 w-full my-9" />
                <div className="flex flex-col justify-center items-center w-full">
                    <h1 className="hidden xs:flex font-montserrat lg:text-3xl md:text-2xl sm:text-lg text-base font-light md:my-8 text-center  border border-black rounded-full md:p-4 p-2">Visit any local Church around you</h1>
                    <div className="my-10 flex  gap-x-10 scc overflow-x-auto overflow-y-hidden w-full">

                        <ChurchCardB 
                            img={cPic19}
                            location={"YABA"}
                            contact={"https://wa.me/+2348166430332"}
                        />
                        <ChurchCardB 
                            img={cPic14}
                            location={"EDE"}
                            contact={"https://wa.me/+2349035499039"}
                        />
                
                        <ChurchCardB 
                            img={cPic5}
                            location={"FUNAAB"}
                            contact={"https://wa.me/+2347032522952"}
                        />
                        
                    </div>
                </div>
                <div className=" flex justify-center">
                    <Link to='/findus' className="bg-orange md:py-3 py-2 md:px-24 px-10 font-poppins rounded-md text-white md:font-bold text-xl hover:bg-white hover:text-orange hover:border-orange border transition ease-in-out duration-500">More Locations</Link>
                </div>
            </div>

            <div className="conf-bg md:h-auto md:flex items-center lg:gap-x-16 md:gap-x-4 px-[11%] md:py-[6%] py-[20%]" id='conferences'>
                
                <div className="flex md:flex-row flex-col justify-center items-center lg:gap-x-4 md:gap-x-2 gap-y-2 mb-10" >
                    <div className="vertical-text font-poppins text-2xl font-medium tracking-widest">
                        <span className="hidden md:block">CONFERENCES</span>
                        <span className="md:hidden block">OUR CONFERENCES</span>

                    </div>
                    <div className="border md:border-r-2 md:h-56 w-56 md:w-0 border-black"/>
                </div>
                <div className="md:grid grid-cols-2 flex flex-col justify-center items-center mx-12 gap-x-28 md:gap-y-6  flex-wrap">
                    <motion.a 
                        href='https://thenewbrook.com/katartismos' 
                        className="text-3xl lg:text-4xl xl:text-5xl font-clashRegular md:my-8 my-6 cursor-pointer"
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    >
                        Katartismos <br/> Congress
                    </motion.a>

                    <motion.a 
                        href='https://whitefieldmissions.org/haystack/'
                        className='flex justify-center items-center cursor-pointer'
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    >
                        <img src={hcLogo} alt="" className="md:w-[380px]  w-[300px] md:my-8 my-6" />
                    </motion.a>

                     <motion.div 
                        className='flex  items-center'
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    >
                        <img src={mlr} alt="" className="md:w-[230px] w-[200px]  md:my-8 my-6" />
                    </motion.div>
                    <motion.a 
                        href='https://whitefieldmissions.org/metamorphoo/' 
                        className='flex justify-center items-center cursor-pointer'
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    >
                        <img src={metaLogo} alt="" className="md:w-[300px] w-[300px] md:my-8 my-6" />
                    </motion.a>
                </div>
                
            </div>

            <div className="flex flex-col justify-center items-center px-[11%] md:py-[6%] py-[18%] bg-warmWhite">
                <h1 className="font-clashRegular text-4xl md:text-5xl lg:text-6xl my-8"> 
                    Give!
                </h1>
                <p className="text-center lg:text-xl md:text-lg text-md max-w-[900px] font-montserrat leading-normal">
                    Your resources will flow through us to water thirsty souls and to enrich the poor by the power of the Gospel. Because of you someone will abound with thanks unto God for a life changed.
                </p>
                <div className="flex md:flex-row flex-col justify-center items-center md:gap-x-12 md:gap-y-0 gap-y-12 mt-10">
                    <div className="flex justify-center flex-col items-center gap-y-4">
                        <div className="flex justify-center items-center py-4 md:px-20 px-16 bg-white border shadow-xl rounded-lg">
                            <img src={flutterwave} alt="" className="h-32" />
                        </div>
                        <a href='https://flutterwave.com/donate/lcbiu2ynpdzu' className="font-montserrat hover:underline underline-offset-2">Pay with Flutterwave</a>
                    </div>
                    <div className="flex justify-center flex-col items-center gap-y-4">
                        <div className="flex justify-center items-center py-4 px-24 bg-white border shadow-xl rounded-lg h-40">
                            <img src={cashapp} alt="" className="h-20" />
                        </div>
                        <a href='/' className="font-montserrat hover:underline underline-offset-2">Cashapp</a>
                    </div>
                </div>
            </div>

            <NewsLetter />
            <Footer/>
        </div>
    )
}

export default Landing