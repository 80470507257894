import React from 'react'
import { Facebook, Instagram, Youtube  } from 'iconsax-react'
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
	return (
		<div className="footer-bg flex flex-col justify-center md:items-center px-7 md:py-[5%] h-[100dvh] md:h-auto">
            <h1 className=" text-greenX text-2xl tracking-[8px] font-bold my-4 text-center"> QUICK LINKS </h1>
           
                <div className="flex md:flex-row flex-col justify-center md:items-center flex-wrap md:w-[800px] md:gap-y-8 gap-y-4 my-6 tracking-wider md:text-lg text-base font-medium text-white font-poppins">
                    <a href="https://www.didachelibrary.net/" target="_blank" rel="noopener noreferrer" className="my-2 md:mx-8">Didache Library</a>
                    <a href="https://whitefieldmissions.org/auxano/" target="_blank" rel="noopener noreferrer" className="my-2 md:mx-8">Auxano Devotional</a>
                    <a href="https://whitefieldmissions.org/metamorphoo/" target="_blank" rel="noopener noreferrer" className="my-2 md:mx-8">Metamorphoo Nation</a>
                    <a href="https://whitefieldmissions.org/lbtc" target="_blank" rel="noopener noreferrer" className="my-2 md:mx-8">LBTC</a>
                    <a href="https://whitefieldmissions.org/haystack/" target="_blank" rel="noopener noreferrer" className="my-2 md:mx-8">Haystack Conference</a>
                    <a href="https://thenewbrook.com/katartismos" target="_blank" rel="noopener noreferrer" className="my-2 md:mx-8">Katartismos</a>
                </div>
            

            <div className=" flex gap-x-12 justify-center items-center">
                <Facebook
                    size="36"
                    color="#FFFFFF"
                    variant="Bold"
                />
                <Instagram
                    size="36"
                    color="#FFFFFF"
                    variant="Bold"
                />
                <FaSquareXTwitter className=" text-white text-[30px] font-bold"/>
                <Youtube
                    size="36"
                    color="#FFFFFF"
                    variant="Bold"
                />
                
            </div>

            <div className="text-greenK my-8 md:text-lg text-sm md:word-spacing-xs">
                © 2024 The Newbrook Church. All Right Reserved. Read our <span className="underline underline-offset-4">Privacy Policy</span>
            </div>
        </div>
	)
}

export default Footer